@import './styles-fonts.scss';
@import './styles-icons.scss';
@import './styles-animations.scss';
@import './styles-config.scss';
@import './styles-generics.scss';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  top: 0px !important;
  position: static !important;
}

* {
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: var(--regular-font);
}

*:focus {
  outline: none !important;
}

.invisible {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
}

html .no-cursor,
html .no-cursor * {
  cursor: none;
}

h1,
h2,
h3 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
