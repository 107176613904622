@font-face {
  font-family: 'Montserrat-Black';
  src: url('./../assets/fonts/montserrat/Montserrat-Black.ttf');
}

@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-BlackItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./../assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-BoldItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./../assets/fonts/montserrat/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraBoldItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('./../assets/fonts/montserrat/Montserrat-ExtraLight.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraLightItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url('./../assets/fonts/montserrat/Montserrat-Italic.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./../assets/fonts/montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-LightItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./../assets/fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-MediumItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./../assets/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./../assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('./../assets/fonts/montserrat/Montserrat-Thin.ttf');
}

@font-face {
  font-family: 'Montserrat-ThinItalic';
  src: url('./../assets/fonts/montserrat/Montserrat-ThinItalic.ttf');
}
