@font-face {
  font-family: 'vsd-icons';
  src: url('./../assets/fonts/icons/vsd-icons.eot');
  src: url('./../assets/fonts/icons/vsd-icons.eot?jblyid#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/icons/vsd-icons.ttf?jblyid') format('truetype'),
    url('./../assets/fonts/icons/vsd-icons.woff?jblyid') format('woff'),
    url('./../assets/fonts/icons/vsd-icons.svg?jblyid#vsd-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vsd-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: '\e900';
}

.icon-back:before {
  content: '\e901';
}

.icon-back-alt:before {
  content: '\e902';
}

.icon-cash:before {
  content: '\e903';
}

.icon-circle:before {
  content: '\e904';
}

.icon-click:before {
  content: '\e905';
}

.icon-clicked:before {
  content: '\e906';
}

.icon-cloche:before {
  content: '\e907';
}

.icon-close:before {
  content: '\e908';
}

.icon-close-alt:before {
  content: '\e909';
}

.icon-config:before {
  content: '\e90a';
}

.icon-confirm:before {
  content: '\e90b';
}

.icon-cutlery:before {
  content: '\e90c';
}

.icon-delete:before {
  content: '\e90d';
}

.icon-down:before {
  content: '\e90e';
}

.icon-down-alt:before {
  content: '\e90f';
}

.icon-edit:before {
  content: '\e910';
}

.icon-filter:before {
  content: '\e911';
}

.icon-forward:before {
  content: '\e912';
}

.icon-forward-alt:before {
  content: '\e913';
}

.icon-gadgets:before {
  content: '\e914';
}

.icon-help:before {
  content: '\e915';
}

.icon-hint:before {
  content: '\e916';
}

.icon-home:before {
  content: '\e917';
}

.icon-left:before {
  content: '\e918';
}

.icon-left-alt:before {
  content: '\e919';
}

.icon-menu:before {
  content: '\e91a';
}

.icon-not-visible:before {
  content: '\e91b';
}

.icon-receipt:before {
  content: '\e91c';
}

.icon-right:before {
  content: '\e91d';
}

.icon-right-alt:before {
  content: '\e91e';
}

.icon-save:before {
  content: '\e91f';
}

.icon-search:before {
  content: '\e920';
}

.icon-select:before {
  content: '\e921';
}

.icon-sound-low:before {
  content: '\e922';
}

.icon-sound-medium:before {
  content: '\e923';
}

.icon-sound-off:before {
  content: '\e924';
}

.icon-sound-on:before {
  content: '\e925';
}

.icon-swipe-left:before {
  content: '\e926';
}

.icon-swipe-right:before {
  content: '\e927';
}

.icon-up:before {
  content: '\e928';
}

.icon-up-alt:before {
  content: '\e929';
}

.icon-visible:before {
  content: '\e92a';
}

.icon-zoom-in:before {
  content: '\e92b';
}

.icon-zoom-out:before {
  content: '\e92c';
}
