:root {
  /** Typography **/

  --black-font: 'Montserrat-Black';
  --black-italic-font: 'Montserrat-BlackItalic';
  --bold-font: 'MontSerrat-Bold';
  --bold-italic-font: 'Montserrat-BoldItalic';
  --extra-bold-font: 'MontSerrat-ExtraBold';
  --extra-bold-italic-font: 'Montserrat-ExtraBoldItalic';
  --extra-light-font: 'Montserrat-ExtraLight';
  --extra-light-italic-font: 'Montserrat-ExtraLightItalic';
  --italic-font: 'Montserrat-Italic';
  --light-font: 'MontSerrat-Light';
  --light-italic-font: 'Montserrat-LightItalic';
  --medium-font: 'MontSerrat-Medium';
  --medium-italic-font: 'Montserrat-MediumItalic';
  --regular-font: 'MontSerrat-Regular';
  --semi-bold-font: 'MontSerrat-SemiBold';
  --semi-bold-italic-font: 'Montserrat-SemiBoldItalic';
  --thin-font: 'Montserrat-Thin';
  --thin-italic-font: 'Montserrat-ThinItalic';

  /** Colors **/
  --primary-color: #ffb000;
  --secondary-color: #013fb3;
  --white-auxiliary-color: #ffffff;
  --gray-auxiliary-color: #202020;
  --successful-color: #4caf50;
  --error-color: #cc2929;
  --attention-color: #e6c317;
  --background: transparent linear-gradient(180deg, #fff 0%, #fff 100%) 0% 0%
    no-repeat padding-box;

  /** Spacing **/
  --common-border-radius: 1.5vmin;
  --common-box-shadow: 0.1vmin 0.5vmin 1vmin #00000029;
}
